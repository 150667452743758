import React from "react";
import {
  OurTeamItem,
  OurTeamMemberAvatar,
  OurTeamMemberName,
  OurTeamMemberTitle,
  OurTeamFrame,
} from "./OurTeam.style";

const OurTeamMember = ({ src, name, title }) => {
  return (
    <OurTeamItem>
      <OurTeamFrame src="our-team-frame.png" />
      <OurTeamMemberAvatar src={src} alt="our team member" />
      <OurTeamMemberName>{name}</OurTeamMemberName>
      <OurTeamMemberTitle>{title}</OurTeamMemberTitle>
    </OurTeamItem>
  );
};

export default OurTeamMember;
