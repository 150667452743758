import styled from "styled-components/macro";
import { device } from "./device";

export const SectionHeader = styled.h2`
  margin-bottom: 0;
  font-family: "Seagram", sans-serif;
  font-weight: 400;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 0.03rem;
  background: linear-gradient(
    0deg,
    #6277d6 0.01%,
    #3a9bb8 50.55%,
    #07c78f 100.05%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media ${device.tablet} {
    font-size: 60px;
    line-height: 75px;
  }
`;

export const SectionSubheader = styled.h5`
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.03rem;
  color: #ffffff;

  @media ${device.tablet} {
    font-size: 18px;
    line-height: 26px;
  }
`;
