import styled from "styled-components/macro";
import { device } from "../../styles/device";

export const NavbarWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 1;
`;

export const NavbarContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
  padding: 26px 0;
  max-width: 1427px;
  border-top: 1px solid #545454;

  @media ${device.laptop} {
    flex-direction: row;
    padding: 26px 20px;
  }
`;

export const Logo = styled.img`
  max-width: 246px;
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 26px;

  @media ${device.laptop} {
    flex-direction: row;
    gap: 65px;
    margin-top: 0;
  }
`;
export const Links = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  @media ${device.laptop} {
    gap: 30px;
  }
`;

export const Link = styled.a`
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.03rem;
  color: #ffffff;
  text-decoration: none;

  @media ${device.laptop} {
    font-size: 18px;
    line-height: 26px;
  }
`;

export const SocialLinks = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
  margin-top: 31px;

  @media ${device.laptop} {
    margin-top: 0;
  }
`;

export const SocialLogo = styled.img`
  width: 32px;
  height: 32px;
`;

export const MobileMenuCloseIcon = styled.img`
  position: absolute;
  right: 20px;
  top: 20px;
  width: 20px;
`;
