import styled from "styled-components/macro";
import { SectionHeader, SectionSubheader } from "../../styles/common.styles";
import { device } from "../../styles/device";

export const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-image: url("data-section-bg-mobile.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #000;

  @media ${device.tablet} {
    padding: 110px 0 100px;
    background-image: url("data-section-bg.png");
    background-size: contain;
  }
`;

export const DataContentWrapper = styled.div`
  max-width: 1363px;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-content: center;

  @media ${device.laptop} {
    flex-direction: row;
    padding: 0 20px;
  }
`;

export const DataSectionHeaderTop = styled(SectionHeader)`
  margin-bottom: 20px;
  text-align: center;

  @media ${device.laptop} {
    display: none;
  }
`;

export const DataSectionHeader = styled(SectionHeader)`
  margin-bottom: 20px;
  display: none;

  @media ${device.laptop} {
    display: block;
  }
`;

export const DataImage = styled.img`
  margin: 0 auto 32px;
  width: 100%;

  @media ${device.tablet} {
    max-width: 684px;
    height: auto;
    max-height: 540px;
    flex-basis: calc(40% - 60px);
    margin: 0 auto;
    flex-shrink: 0;
  }
`;

export const DataSectionText = styled(SectionSubheader)`
  text-align: left;
  font-size: 16px;
  line-height: 28px;

  @media ${device.laptop} {
    font-size: 18px;
    line-height: 30px;
  }
`;
