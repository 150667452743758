import React, { useState } from "react";
import {
  NavbarWrapper,
  NavbarContent,
  Logo,
  Links,
  Link,
  SocialLinks,
  SocialLogo,
  HamburgerMenu,
  MobileMenuWrapper,
  MobileMenuItem,
  MobileMenuCloseIcon,
} from "./Navbar.style";

const Navbar = () => {
  const [menuOpened, setMenuOpened] = useState(false);
  return (
    <NavbarWrapper>
      <NavbarContent>
        <a href="/">
          <Logo src="logo.png" />
        </a>
        <Links>
          <Link href="#data">About</Link>
          <Link href="#collection">Collection</Link>
          <Link href="#team">Team</Link>
          <Link href="https://www.notion.so/Litepaper-4c19e9368c4f43f282c8e1ef0da69931#69a4445d16614c8bbc930079419ffe3e">Whitepaper</Link>
          <Link href="#faq">FAQ</Link>
          <SocialLinks>
            <a
              href="https://twitter.com/wisewhales"
              target="_blank"
              rel="noreferrer"
            >
              <SocialLogo src="twitter-logo.svg" />
            </a>
            <a
              // TODO: change link
              href="https://discord.gg/7jSgZTwJrU"
              target="_blank"
              rel="noreferrer"
            >
              <SocialLogo src="discord-logo.svg" />
            </a>
          </SocialLinks>
        </Links>
        <HamburgerMenu
          src="hamburger-icon.svg"
          onClick={() => setMenuOpened(true)}
        />
        <MobileMenuWrapper opened={menuOpened}>
          <MobileMenuCloseIcon
            src="close.svg"
            onClick={() => setMenuOpened(false)}
          />
          <MobileMenuItem href="#data">About</MobileMenuItem>
          <MobileMenuItem href="#collection">Collection</MobileMenuItem>
          <MobileMenuItem href="#team">Team</MobileMenuItem>
          <MobileMenuItem href="https://www.notion.so/Litepaper-4c19e9368c4f43f282c8e1ef0da69931#69a4445d16614c8bbc930079419ffe3e">Whitepaper</MobileMenuItem>
          <MobileMenuItem href="#faq">FAQ</MobileMenuItem>
        </MobileMenuWrapper>
      </NavbarContent>
    </NavbarWrapper>
  );
};

export default Navbar;
