import React from "react";
import {
  DataWrapper,
  DataSectionHeaderTop,
  DataSectionHeader,
  DataContentWrapper,
  DataSectionText,
  DataImage,
} from "./Data.style";

const DataSection = () => {
  return (
    <DataWrapper id="data">
      <DataContentWrapper>
        <DataSectionHeaderTop>The Data</DataSectionHeaderTop>
        <DataImage src="data-section-image.png" alt="data" />
        <div>
          <DataSectionHeader>The Data</DataSectionHeader>
          <DataSectionText>
            Information is power and the wisest whales know things that you
            don’t. What are the Wise Whales holding right now? What plays have
            they made today or earlier this week? We present to you the
            aggregate information of these wisest of whales, from tokens and
            DeFi positions to NFT holdings. Find the hottest collections, the
            current trends and the protocols that the smartest players in the
            game are interacting with. Whales are on the move.
          </DataSectionText>
        </div>
      </DataContentWrapper>
    </DataWrapper>
  );
};

export default DataSection;
