import styled from "styled-components/macro";
import { SectionHeader, SectionSubheader } from "../../styles/common.styles";
import { device } from "../../styles/device";

export const CollectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 100px;
  background-image: url("collection-bg-mobile.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #000;

  @media ${device.tablet} {
    padding: 32px 0 161px;
    background-image: url("collection-bg.png");
  }
`;

export const CollectionContentWrapper = styled.div`
  max-width: 1363px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CollectionSectionHeader = styled(SectionHeader)`
  margin-bottom: 20px;
  text-align: center;
`;

export const CollectionSectionSubheader = styled(SectionSubheader)`
  margin-bottom: 72px;
  text-align: center;
  max-width: 1000px;
`;

export const CollectionItemMobile = styled.img`
  width: 100%;
  max-width: 232px;

  @media ${device.laptop} {
    display: none;
  }
`;

export const CollectionItem = styled.img`
  display: none;

  @media ${device.laptop} {
    display: block;
  }
`;
