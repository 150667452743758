import styled from "styled-components/macro";
import { device } from "../../styles/device";

export const FAQWrapper = styled.div`
  position: relative;
  padding: 0 20px 250px;
  background-image: url("faq-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media ${device.laptop} {
    padding: 0 20px 224px;
  }

  h2 {
    margin-bottom: 10px;
  }
`;

export const FAQInnerWrapper = styled.div`
  max-width: 1363px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FAQContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;

  & > div:first-child {
    margin: 30px 0;

    @media ${device.laptop} {
      margin: 20px 40px 0 0;
    }
  }

  @media ${device.laptop} {
    flex-direction: row;
    align-items: center;
    margin-top: 22px;
  }
`;

export const FAQImage = styled.img`
  width: auto;
  height: 376px;
  max-height: 376px;
  @media ${device.laptop} {
    width: 50%;
    height: auto;
    max-height: unset;
  }
`;

export const AccordionWrapper = styled.div`
  margin-top: 50px;

  @media ${device.laptop} {
    width: 51%;
    margin-top: 60px;
  }

  & > div:not(:first-child) {
    margin-top: 19px;
  }

  & div > p:first-child {
    padding: 19px 26px 15px;
  }
`;
