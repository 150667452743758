import styled from "styled-components/macro";
import { device } from "../../styles/device";

export const NavbarWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
`;

export const NavbarContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 20px;
`;

export const Logo = styled.img`
  max-width: 246px;
`;

export const Links = styled.div`
  display: none;

  @media ${device.laptop} {
    display: flex;
    align-items: center;
    gap: 30px;
  }
`;

export const Link = styled.a`
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.03rem;
  color: #ffffff;
  text-decoration: none;
`;

export const SocialLinks = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
  margin-left: 30px;
`;

export const SocialLogo = styled.img`
  width: 32px;
  height: 32px;
`;

export const MobileMenuCloseIcon = styled.img`
  position: absolute;
  right: 20px;
  top: 20px;
  width: 20px;
`;

export const HamburgerMenu = styled.img`
  @media ${device.laptop} {
    display: none;
  }
`;

export const MobileMenuWrapper = styled.div`
  display: ${({ opened }) => (opened ? "block" : "none")};
  position: absolute;
  width: 100%;
  left: 0;
  background: #000000;
  top: 0;
  padding: 40px 20px 20px;
  z-index: 1;
`;

export const MobileMenuItem = styled(Link)`
  display: block;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  border-bottom: 1px solid #3a9bb8;
`;
