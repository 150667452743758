import React, { useState } from "react";
import {
    FAQWrapper,
    FAQInnerWrapper,
    FAQContent,
    AccordionWrapper,
    FAQImage,
  } from "./FAQ.style";
import Col from "react-bootstrap/Col";
import Accordion from 'react-bootstrap/Accordion';
export const ModalAccordian = () => {
    const [Policies, setPolcies] = useState(0);
    const handleModalAccordion = (id) => setPolcies(id);
    return (
        <Col>
        <Accordion className="LegalAccordion" size="lg">
        <Accordion.Item eventKey="0">
          <Accordion.Header >Privacy Policy</Accordion.Header>
          <Accordion.Body>
            <b>Privacy Policy</b>
            <br/>
            <small>Last updated: July 18<sup>th</sup>, 2022</small>
            <br/>
            <b>INTRODUCTION</b>
            <br/>
            <p>
                <small>
                    GRAPHD Inc. and our affiliates and subsidiaries (&ldquo;Company&rdquo;, &ldquo;we&rdquo;, &ldquo;us&rdquo;, &ldquo;our&rdquo;) respect your privacy and are committed to protecting it by complying with this policy.
                    This policy describes:
                    <br/>
                    <ul>
                        <li>
                            How we collect, use, disclose, and protect the personal information of our customers and website users (&ldquo;you&rdquo;).
                        </li>
                        <li>
                            Describes the types of information we may collect from you or that you may provide when you visit the website https://wisewhales.xyz/ (our &ldquo;Site&rdquo;).
                        </li>
                        <li>
                            Our practices for collecting, using, maintaining, protecting, and disclosing that information.
                        </li>
                    </ul>
                    We will only use your personal information in accordance with this policy unless otherwise required by applicable law. We take steps to ensure that the personal information that we collect about you is adequate, relevant,
                    not excessive, and used for limited purposes in line with our business operations.
                    <br/>
                    Privacy laws in Canada generally define "personal information" as any information about an identifiable individual, which includes information that can be used on its own or with other information to
                    identify, contact, or locate a single person. Personal information does not include business contact information, including your name, title, or business contact information.
                    <br/>
                    This policy applies to information we collect, use, or disclose about you:
                    <br/>
                    <ul>
                        <li>
                            On this Site
                        </li>
                        <li>
                            In emails, letters, or other electronic messages sent to you
                        </li>
                    </ul>
                    The Site may include links to third-party websites, plug-ins, services, social networks, or applications. Clicking on those links or enabling those connections may allow
                    the third party to collect or share data about you. If you leave our Site by following a link to a
                    third-party website or engaging a third-party plugin, please note that these third parties have their own privacy policies and we do not accept any responsibility or
                    liability for these policies (which we encourage you to review separately). We do not control these third-party websites, and do not take any responsibility for third-party privacy practices.
                    <br/>
                    Please read this policy carefully to understand our policies and practices for collecting, processing, and storing your information. If you do not agree with our policies and practices,
                    do not use our Site. By accessing or using this Site, you indicate that you understand, accept, and consent to the practices described in this policy. 
                    <br/>
                    We may update or change this policy from time to time at our discretion. Your continued use of this Site after we make changes indicates that you accept and consent to those changes,
                    so please check the policy periodically for updates.
                    <br/>
                </small>
                </p>
                <b>INFORMATION WE COLLECT ABOUT YOU</b>
                <br/>
                <p>
                    <small>
                    We may collect and use several types of personal information from and about you, including:
                    <br/>
                    <ul>
                        <li>
                        <b>Personal information,</b> that we can reasonably use to directly or indirectly identify you, such as your name, mailing address, e-mail address,
                        telephone number, Internet protocol (IP) address used to connect your computer to the Internet, user name or other similar identifiers,
                        and any other identifier we may use to contact you ("Personal Information"). At the moment, we do not collect such Personal Information,
                        but we may collect Personal Information in the future. 
                        </li>
                        <li>
                        <b>Technical information,</b> including your login information, blockchain-based digital wallet address (to verify your ownership of a Wise Whales NFT,
                        as described in our Terms of Use), browser type and version, time zone setting, browser plug-in types and versions, operating system and platform,
                        or information about your internet connection, the equipment you use to access our Site, and usage details.
                        </li>
                        </ul>
                    </small>
                </p>
                <b>HOW WE COLLECT INFORMATION ABOUT YOU</b>
                <br/>
                <p>
                    <small>
                    We use different methods to collect your information, including through:
                    <br/>
                    <ul>
                        <li>
                            <b>Direct interactions</b> with you when you provide it to us, for example, by filling in forms or corresponding with us by phone, email, or otherwise.
                        </li>
                        <li>
                            <b>Automated technologies or interactions,</b> as you navigate through our Site. Information collected automatically may include usage details, IP addresses, and information collected through cookies.
                        </li>
                    </ul>


                    </small>
                </p>
                <br/>
                <b>Information You Provide to Us</b>
                <br/>
                <p>
                    <small>
                    The information we collect directly from you on or through our Site may include:
                    <br/>
                    <ul>
                        <li>
                            Information that you provide by filling in forms on our Site. This includes information provided at the time of subscribing to our service,
                            requesting access to a White List or future NFT drops or requesting further services. We may also ask you for information when you enter a
                            contest or promotion sponsored by us, and when you report a problem with our Site.
                        </li>
                        <li>
                            Records and copies of your correspondence (including email addresses), if you contact us.
                        </li>
                        <li>
                            Your responses to surveys that we might ask you to complete for research purposes.
                        </li>
                        <li>
                            Your search queries on the Site.
                        </li>
                    </ul>
                    </small>
                </p>
                <br/>
                <b>Information We Collect Through Cookies and Other Automatic Data Collection Technologies</b>
                <br/>
                <p>
                    <small>
                    As you navigate through and interact with our Site, we may use cookies or other automatic data collection technologies to
                    collect certain information about your equipment, browsing actions, and patterns, including:
                    <br/>
                    <ul>
                        <li>
                            Details of your visits to our Site, including traffic data, location data, logs, and other communication data and the resources that you access and use on the Site.
                        </li>
                        <li>
                            Information about your computer and internet connection, including your IP address, operating system, and browser type.
                        </li>
                    </ul>
                    The technologies we use for this automatic data collection may include:
                    <ul>
                        <li>
                            <b>Cookies (or browser cookies).</b> A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser.
                            However, if you select this setting you may be unable to access certain parts of our Site. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies 
                            when you direct your browser to our Site. 
                        </li>
                        <li>
                            <b>Flash Cookies.</b> Certain features of our Site may use local stored objects (or Flash cookies) to collect and store information about your preferences and navigation to, 
                            from, and on our Site. Flash cookies are not managed by the same browser settings that are used for browser cookies. 
                        </li>
                    </ul>
                    </small>
                </p>
                <br/>
                <b>HOW WE USE YOUR INFORMATION</b>
                <br/>
                <p>
                    <small>
                    We use information that we collect about you or that you provide to us, including any personal information:
                    <ul>
                        <li>
                            To present our Site and its contents to you.
                        </li>
                        <li>
                        To provide you with information, products, or services that you request from us.
                        </li>
                        <li>
                        To fulfill the purposes for which you provided the information or that were described when it was collected, or any other purpose for which you provide it.
                        </li>
                        <li>
                        To carry out our obligations and enforce our rights arising from any contracts with you, including for billing and collection or to comply with legal requirements.
                        </li>
                        <li>
                        To notify you about changes to our Site or any products or services we offer or provide though it.
                        </li>
                        <li>
                        In any other way we may describe when you provide the information.
                        </li>
                        <li>
                        For any other purpose with your consent.
                        </li>                         
                    </ul>
                    </small>
                </p>
                <br/>
                <b>DISCLOSURE OF YOUR INFORMATION</b>
                <br/>
                <p>
                    <small>
                    We may disclose aggregated information about our users, and information that does not identify any individual, without restriction.
                    <br/>
                    We may disclose personal information that we collect or you provide as described in this privacy policy:

                    <ul>
                        <li>
                        To our subsidiaries and affiliates.
                        </li>
                        <li>
                        In accordance with applicable law, such as in the course of a business transaction in which personal information held by us about our customers and users is among the assets transferred.
                        </li>
                        <li>
                        To contractors, service providers, and other third parties we use to support our business (such as analytics and search engine providers that assist us with Site improvement and optimization)
                        and who are contractually obligated to keep personal information confidential, use it only for the purposes for which we disclose it to them, and to process the personal information with the same
                        standards set out in this policy. 
                        </li>
                        <li>
                        For any other purpose disclosed by us when you provide the information.
                        </li>
                        <li>
                        With your consent
                        </li>
                    </ul>
                    We may also disclose your personal information:
                    <ul>
                        <li>
                        To comply with any court order, law, or legal process, including to respond to any government or regulatory request, in accordance with applicable law.
                        </li>
                        <li>
                        To enforce or apply our terms of use and other agreements, including for billing and collection purposes
                        </li>
                    </ul>

                    </small>
                </p>
                <br/>
                <b>DATA SECURITY</b>
                <br/>
                <p>
                    <small>
                    Except as otherwise permitted or required by applicable law or regulation, we will only retain your personal information for as long as necessary to fulfill the purposes we collected it for,
                    including for the purposes of satisfying any legal, accounting, or reporting requirements. Under some circumstances we may anonymize your personal information so that it can no longer be associated with you.
                    We reserve the right to use such anonymous and de-identified data for any legitimate business purpose without further notice to you or your consent. 
                    </small>
                </p>
                <br/>
                <b>CHANGES TO THIS PRIVACY POLICY</b>
                <br/>
                <p>
                    <small>
                    Our Site is not intended for children under 18 years of age. No one under age 18 may provide any information to or on the Site. We do not knowingly collect personal information from children under 18. If you are under 18,
                    do not use or provide any information on this Site or on or through any of its features/register on the Site, make any purchases through the Site, use any of the interactive or public comment features of this Site, or
                    provide any information about yourself to us, including your name, address, telephone number, email address, or any screen name or user name you may use. If we learn we have collected or received personal information 
                    from a child under 18, we will delete that information. If you believe we might have any information from or about a child under 18, please contact us at wisewhales.sol@gmail.com. 
                    </small>
                </p>
                <br/>
                <b>WITHDRAWING YOUR CONSENT</b>
                <br/>
                <p>
                    <small>
                    Where you have provided your consent to the collection, use, and transfer of your personal information, you may have the legal right to withdraw your consent under certain circumstances. To withdraw your consent,
                    if applicable, contact us at wisewhales.sol@gmail.com. Please note that if you withdraw your consent we may not be able to provide you with a particular product or service, and it may interfere with your use of 
                    the Site. We will explain the impact to you at the time to help you with your decision. 
                    </small>

                </p>
                <br/>
                <b>CONTACT INFORMATION AND CHALLENGING COMPLIANCE</b>
                <br/>
                <p>
                    <small>
                    We welcome your questions, comments, and requests regarding this privacy policy and our privacy practices, including any complaints or challenges you would like to make to it. Please contact us our privacy
                    representative at wisewhales.sol@gmail.com and we would be happy to discuss with you. 
                    </small>
                </p>
                        
















            


          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Terms of Service</Accordion.Header>
          <Accordion.Body>
            <b>
            Terms of Use
            </b>
            <br/>
            <small>
                Last updated: July 12<sup>th</sup>, 2022
                <br/>
                website <a href="https://wisewhales.xyz/">https://wisewhales.xyz/</a>
                <br/>
                Please read these Terms and of Use carefully before using our Platform.
                <br/>
            </small>
            <br/>
            <b>AGREEMENT </b>
            <p>
                <small>
                These Terms of Use (the “Terms”) apply to your access and use of the services, features and functionalities (the “Services”) available at https://wisewhales.xyz/ (the "Site" and together with the Services,
                the “Platform”) and your ownership and display of the Wise Whales non-fungible tokens (the “WW NFTs”). These Terms constitute a legally binding agreement between you (“you”, “your”), whether personally or on
                behalf of an entity, and Graphd Inc. and its affiliates and subsidiaries (“Company,” “we,” “us,” or “our”). These Terms expressly incorporate any other documents referenced herein, including our Privacy Policy
                as well as all content (“Content”) offered on or through the Platform, including the WW NFTs. By accessing the Platform, you are deemed to have read, understood, and agreed to be bound by and accept these Terms and
                our Privacy Policy. If you do not agree with these Terms and our Privacy Policy, then you are expressly prohibited from using the Platform and you must discontinue use immediately.
                <br/>
                Any Services or information provided on our Platform should not be used by any person or entity in a jurisdiction or country where such Services or information is illegal or not permitted. 
                If you decide to access our Platform nevertheless, you shall be solely responsible for compliance with laws, to the extent such laws are applicable. We will not be responsible for your use of the Platform in any way that violates any laws. 
                This Platform is intended for users who are 18 years old or older, or who have reached the age of majority in their country or jurisdiction. 
                </small>
            </p>
            <br/>
            <b>ACCEPTABLE USE</b>
            <p>
                <small>
                You agree to use the Platform in accordance with any and all applicable laws and regulations. Under these Terms, you shall not:
                <ol type="a">
                    <li>
                    download, modify, copy, distribute, transmit, display, perform, reproduce, duplicate, publish, license, create derivative works from, or offer for sale any information contained on, or obtained from or 
                    through, the Platform and the WW NFTs, except for temporary files that are automatically cached by your web browser for display purposes, or as otherwise expressly permitted in these Terms; 
                    </li>
                    <li>
                        duplicate, decompile, reverse engineer, disassemble or decode the Platform (including any underlying algorithm), or attempt to do so; 
                    </li>
                    <li>
                    use, reproduce or remove any copyright, trademark, service mark, trade name, slogan, logo or image displayed on or through the Platform or the WW NFTs; 
                    </li>
                    <li>
                    use cheats, automation software (bots), hacks, modifications (mods) or any other unauthorized third-party software; 
                    </li>
                    <li>
                    access or use the Platform in any manner that could disable, overburden, damage, disrupt or impair the Platform or interfere with any other party’s access to or use of the Platform or use any device,
                    software or routine that causes the same;
                    </li>
                    <li>
                    attempt to gain unauthorized access to, interfere with, damage or disrupt the Platform, or the computer systems or networks connected to the Platform; 
                    </li>
                    <li>
                    circumvent, remove, alter, deactivate, degrade or thwart any technological measure or content protections of the Platform; 
                    </li>
                    <li>
                    use any robot, spider, crawlers or other automatic device, process, software or queries that intercepts, “mines,” scrapes or otherwise accesses the Platform to monitor, extract, copy or collect information or data from
                    or through the Platform, or engage in any manual process to do the same; 
                    </li>
                    <li>
                    introduce any viruses, trojan horses, worms, logic bombs or other materials that are malicious or technologically harmful into our systems; or,
                    </li>
                    <li>
                    use the Platform for illegal, harassing, unethical, or disruptive purposes, or otherwise use the Platform in a manner that is fraudulent, deceptive, threatening, defamatory, obscene, hateful,
                    or otherwise objectionable or that creates any risk of harm, damage or liability to us, including without limitation, reputational risks, data loss, or damage to the Platform or third parties.
                    </li>
                </ol>
                <br/>
                We reserve the right, in our sole discretion, to determine whether any use of the Platform is in violation of these Terms or fails to constitute what we consider to be acceptable use.
                </small>
            </p>
            <br/>
            <b>USER ACCOUNT AND WALLETS</b>
            <p>
                <small>
                You must own a WW NFT in order to access some or all of the Services available on the Site. WW NFTs can be purchased on third-party marketplaces. You will be required to connect your supported blockchain-based
                digital wallet (“Supported Wallet”) to the Site in order to be confirm your ownership of a WW NFT. By connecting your Supported Wallet to the Site and accessing Services as a result, you are deemed to have read,
                understood, and agreed to be bound by these Terms and our Privacy Policy.
                <br/>
                <br/>
                Supported Wallets are provided by third parties not associated or affiliated with us. You hereby acknowledge and agree that Company is not the provider of the Supported Wallets and is not responsible for any
                compatibility issues, errors or bugs in the Services or Supported Wallets caused in whole or in part by the Supported Wallets or its providers. Access and use of the Supported Wallets are at your own risk and
                discretion and subject to external terms. If you use, connect or link a Supported Wallet to the Site, you accept and acknowledge that you are bound by the terms and conditions applicable to such Supported Wallet.
                <br/>
                <br/>
                We will decide, at our sole discretion, which Supported Wallet can be connected to the Platform. Without limiting any other rights, we may terminate your access or use of the Platform if we determine that your
                Supported Wallet does not hold a WW NFT.
                <br/>
                <br/>
                You are solely responsible for the security of your Supported Wallet, including any associated credentials, passwords, seed words, seed phrases, and keys. We shall not be held responsible for
                any risk associated with your use and access of a Supported Wallet. We cannot retrieve your WW NFT from your Supported Wallet in the event that you lose access to your Supported Wallet and have
                no control over your purchase, transfer, storage, sale, use, ownership or maintenance of a WW NFT. We make no representations or warranties in relation to and accept no responsibility or liability
                to you in connection with your use of a Supported Wallet.
                </small>
            </p>
            <br/>
            <b>TRANSFER OR SALE OF WW NFTS (SECONDARY SALES)</b>
            <p>
                <small>
                You and, where applicable, all subsequent owners of the WW NFT, shall have the right to resell the WW NFT on third-party marketplaces (an “Authorized Marketplace”)
                which allow the purchase and sale of NFTs, provided that:
                <ol type="a">
                    <li>
                        the transferee or purchaser understands and accepts these Terms and the terms of the marketplace (if applicable); 
                    </li>
                    <li>
                        the marketplace cryptographically verifies each NFT owner's rights to display and list an NFT for sale to ensure that only the actual owner of an NFT can proceed with its resale; 
                    </li>
                    <li>
                        the marketplace is not a third-party marketplace and does not sell or transfer any NFTs to a third-party;
                    </li>
                </ol>
                <br/>
                We are not responsible for any liability arising from the sale or transfer of your WW NFT to a third-party, whether directly or through an Authorized Marketplace.
                <br/>
                <br/>
                Additional fees (including gas fees) may be required at the time of transfer or sale of an NFT on an Authorized Marketplace. We do not monitor nor benefit from these fees. We may however benefit from a percentage of the resale price of your WW NFT,
                as set forth on our Site and subject to the Authorized Marketplace's policy on secondary sales.
                </small>
            </p>
            <br/>
            <b>DISCLAIMERS</b>
            <p>
                <small>
                The information contained within the Platform is for general informational purposes only and is neither an offer to sell nor a solicitation of an offer to purchase any security and may not be relied upon 
                for investment purposes. Any commentaries and information contained on the Platform should not be considered investment advice or recommendation to purchase, hold or sell any security or cryptocurrency,
                non-fungible token, asset, token or any other such asset (“Crypto Asset”). 
                <br/>
                <br/>
                Information on the Platform is not intended to provide legal, accounting, financial or tax advice, and should not be relied upon in that regard.
                Please consult with your professional advisor with respect to your particular circumstances.
                <br/>
                <br/>
                Nothing in this Platform is, or should be relied on as, a promise or representation about the future. The Platform may contain forward-looking statements. You may identify these forward-looking statements
                by the use of words such as “believe”, “expect”, “potential”, “continue”, “may”, “will”, “should”, “seek”, “approximately”, “predict”, “intend”, “plan”, “estimate”, “anticipate” or other comparable words.
                These forward-looking statements are subject to various risks, uncertainties and assumptions. Accordingly, there are or will be important factors that could cause actual outcomes or results to differ materially
                from those indicated in these potential statements. Should any assumptions underlying potential forward-looking statements contained on this website prove to be incorrect, the actual outcome or results may 
                differ materially from outcomes or results projected in these statements. The Company does not undertake any obligation to update or review any forward-looking statement, whether as a result of new information,
                future developments or otherwise, except as required by applicable law or regulation.
                <br/>
                <br/>
                Company is not currently licensed or registered in any jurisdiction as a dealer, adviser, investment fund manager, marketplace, alternative trading system, investment company,
                or otherwise licensed or registered with any securities regulatory authority.
                </small>
            </p>
            <br/>
            <b>FINANCIAL ADVISORS AND BROKERS</b>
            <p>
                <small>
                If you use the Platform to provide any services to any third-parties, such as, without limitation, if you are an investment advisor or broker, you are fully responsible for all services and recommendations,
                and represent and warrant that you are appropriately qualified and certified to do so, possessing all necessary licenses and permits to do so. You will not state nor imply to any customer or client any
                responsibility of us for any decisions or recommendations. In addition: (a) you are solely responsible for all advice, recommendations, or services you provide to your clients, even if you use our Platform in
                whole or in part to create such advice; (b) we have no liability whatsoever to your clients; (c) we are not responsible for any disputes between you and your clients; (d) you will not state or imply to your 
                clients anything contrary to all disclaimers, warranty limitations, and limitations of liability, by us herein or otherwise with respect to the Platform. You hereby agree to indemnify, defend, and hold us harmless 
                from and against any allegation, loss, liability, or claim made against us by any of your clients or otherwise arising out of any breach of this section (or any other portion of these Terms) by you or your agents, 
                or any failure by you or your agents to follow any applicable laws, rules, and regulations.
                </small>
            </p>
            <br/>
            <b>DISCLAIMER OF WARRANTIES</b>
            <p>
                <small>
                TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, WE EXPRESSLY DISCLAIM ANY RESPONSIBILITY FOR ANY LOSSES, HARM OR LIABILITIES INCURRED AS A RESULT OF USING OUR PLATFORM. THE INFORMATION ON THE PLATFORM IS
                PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS AND YOU AGREE THAT YOUR USE OF THE PLATFORM IS AT YOUR OWN RISK. WE EXPRESSLY DISCLAIM, AND YOU WAIVE, ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, 
                INCLUDING, WITHOUT LIMITATION, WARRANTIES OR CONDITIONS OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE, DURABILITY, AVAILABILITY, TIMELINESS, ACCURACY, OR COMPLETENESS, 
                ALL OF WHICH ARE HEREBY DISCLAIMED BY THE COMPANY TO THE FULLEST EXTENT PERMITTED BY LAW. WE EXPRESSLY DISCLAIM ANY RESPONSIBILITY FOR ANY LOSSES, HARM OR LIABILITIES INCURRES AS A RESULT OF YOUR INVESTMENT 
                DECISIONS. WE CANNOT AND DO NOT GUARANTEE OR WARRANT THAT FILES OR DATA AVAILABLE FOR DOWNLOADING FROM THE INTERNET OR THE PLATFORM WILL BE FREE OF VIRUSES OR OTHER DESTRUCTIVE CODE. YOU ARE SOLELY AND ENTIRELY 
                RESPONSIBLE FOR YOUR USE OF THE PLATFORM AND YOUR COMPUTER, INTERNET, AND DATA SECURITY. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY DENIAL-OF-SERVICE ATTACK, 
                DISTRIBUTED DENIAL-OF-SERVICE ATTACK, OVERLOADING, FLOODING, MAILBOMBING, OR CRASHING, VIRUSES, TROJAN HORSES, WORMS, LOGIC BOMBS, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, 
                COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE PLATFORM OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
                <br/>
                <br/>
                WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS AND TAKE NO RESPONSIBILITY FOR YOUR PURCHASE, TRANSFER, STORAGE, USE, OWNERSHIP OR MAINTENANCE OF YOUR WW NFT OR FOR ANY EVENT THAT RESULTS IN YOUR INABILITY 
                TO ACCESS YOUR WW NFT.
                <br/>
                <br/>
                NOTHING IN THESE TERMS SHALL EXCLUDE OR LIMIT LIABILITY OF EITHER PARTY FOR FRAUD, DEATH OR BODILY INJURY CAUSED BY NEGLIGENCE, VIOLATION OF LAWS, OR ANY OTHER ACTIVITY THAT CANNOT 
                BE LIMITED OR EXCLUDED BY LEGITIMATE MEANS.
                <br/>
                <br/>
                SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES IN CONTRACTS WITH CONSUMERS, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.
                </small>
            </p>
            <br/>
            <b>LIMITATION OF LIABILITY</b>
            <p>
                <small>
                EXCEPT WHERE SUCH EXCLUSIONS ARE PROHIBITED BY LAW, UNDER NO CIRCUMSTANCE WILL THE COMPANY NOR ITS PARENT, SUBSIDIARIES, AFFILIATES OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, 
                CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS BE LIABLE FOR NEGLIGENCE, GROSS NEGLIGENCE, NEGLIGENT MISREPRESENTATION, FUNDAMENTAL BREACH, DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, INCLUDING ANY 
                DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING, BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS 
                OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, BREACH OF PRIVACY, OR OTHERWISE, WHETHER OR NOT YOU WERE ADVISED OF THE 
                POSSIBILITY OF SUCH LOSS OR DAMAGE.
                <br/>
                <br/>
                IN NO EVENT SHALL THE AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT BY COMPANY OR ITS PARENT, SUBSIDIARIES, AFFILIATES OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, 
                CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS EXCEED $5,000 CAD.
                <br/>
                <br/>
                YOU HEREBY ACKNOWLEDGE AND AGREE THAT THE LIMITATIONS OF LIABILITY IN THIS SECTION OF THE TERMS OF USE ARE AN ESSENTIAL PART OF THE BASIS OF THE BARGAIN BETWEEN YOU AND COMPANY, AND WILL APPLY EVEN IF THE REMEDIES 
                AVAILABLE HEREUNDER ARE FOUND TO FAIL THEIR ESSENTIAL PURPOSE.
                </small>
            </p>
            <br/>
            <b>INDEMNIFICATION</b>
            <p>
                <small>
                To the fullest extent permitted by applicable law, you agree to indemnify, defend and hold harmless Company and its respective past, 
                present and future employees, officers, directors, contractors, consultants, equity holders, suppliers, vendors, service providers, parent companies, and their agents, representatives, predecessors, 
                successors and assigns (individually and collectively, the “Company Parties”), from and against all actual or alleged third party claims, damages, awards, judgments, losses, liabilities, obligations, 
                penalties, interest, fees, expenses (including, without limitation, attorneys’ fees and expenses) and costs (including, without limitation, court costs, costs of settlement and costs of pursuing 
                indemnification and insurance), of every kind and nature whatsoever, whether known or unknown, foreseen or unforeseen, matured or unmatured, or suspected or unsuspected, in law or equity, whether in tort, 
                contract or otherwise (collectively, “Claims”), including, but not limited to, damages to property or personal injury, that are caused by, arise out of or are related to (a) your use or misuse of the Platform or 
                WW NFT, (b) your violation of these Terms, (c) your violation of the rights of a third party, including another user and (d) your failure to pay any taxes or sales taxes in connection with your WW NFT or your 
                possible use of the Platform. You agree to promptly notify us of any third-party Claims and cooperate with the Company Parties in defending such Claims. You further agree that the Company Parties shall have 
                control of the defense or settlement of any third-party Claims. THIS INDEMNITY IS IN ADDITION TO, AND NOT IN LIEU OF, ANY OTHER INDEMNITIES SET FORTH IN A WRITTEN AGREEMENT BETWEEN YOU AND US.
                </small>
            </p>
            <br/>
            <b>THIRD-PARTY LINKS TO OTHER SITES</b>
            <p>
                <small>
                The Platform may contain links to independent third-party websites or services that are not owned or controlled by us. We have no editorial control and are not responsible for the content of such third-party 
                websites, nor do we endorse the accuracy or reliability of such content. You further acknowledge and agree that Company and its officers, directors, employees or agents, shall not be responsible or liable, 
                directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with your use or reliance on the content available on or through any such third-party websites. We strongly 
                recommend that you carefully read the terms and conditions and privacy policies of any third-party websites that you visit.
                </small>
            </p>
            <br/>
            <b>INTELLECTUAL PROPERTY</b>
            <p>
                <small>
                Unless otherwise stated in writing by us, we retain the sole and exclusive property on all intellectual property rights, including all copyright and trademark, in all content and other materials contained on our 
                Platform or provided in connection with our Platform. This includes but is not limited to any and all source code, databases, functionality, software, photos, designs, graphics, images, pictures, icons, audiovisual 
                content, videos, sounds, text, source code, selection of information, compilation of data, aggregated data, collective work, trademarks, logos, slogans, arrangement and presentation of all content and the overall 
                design of the Platform. You shall receive no license or usage rights to the Platform other than what is explicitly stated in these Terms. The Company logo and any Company product or service names, logos, or slogans 
                that may appear on the Platform or elsewhere are trademarks of Company or our affiliates, and may not be copied, imitated or used, in whole or in part, without our prior written permission. In addition, the look and 
                feel of the Platform's Content, including without limitation, all page headers, custom graphics, button icons, and scripts constitute the service mark, trademark, or trade dress of Company and may not be copied, 
                imitated, or used, in whole or in part, without our prior written permission.
                </small>
            </p>
            <br/>
            <b>WW NFT LICENSE</b>
            <p>
                <small>
                By agreeing to these Terms, you agree to be bound by the license (“License”) below governing the rights you have to any WW NFTs you own. You acknowledge and accept that the License may limit or restrict rights 
                that you already have to the WW NFT. If, prior to agreeing to these Terms, you have any rights to a WW NFT that are incompatible or inconsistent with the License (“Remaining Rights”), you hereby agree to transfer 
                all Remaining Rights to Company. 
                <br/>
                <br/>
                <ol type="a">
                    <li>
                    Ownership<br/>
                    You acknowledge and agree that we (or, as applicable, our licensors) own all worldwide legal right, title and interest in and to the art, design, drawing, or other pictorial or graphic work of 
                    authorship that may be underlying or associated with a WW NFT that you own (the “Art”), and all intellectual property rights therein. The rights that you have in and to the Art are limited to those 
                    described in this License. We reserve all rights in and to the Art not expressly granted to you in this License.
                    </li>
                    <li>
                    License<br/>
                    Subject to and conditioned upon your continued compliance with the terms of this License, Company grants you a worldwide, non-exclusive, royalty-free license to use, copy, and display the Art 
                    associated with your WW NFT, so long as you use the WW NFT for your own personal, non-commercial use or are listing or selling the WW NFT on an Authorized Marketplace.
                    </li>
                    <li>
                    Restrictions<br/>
                    You agree not to use the Art associated with your WW NFT in connection with images, videos, or other forms of media that depict hatred, intolerance, violence, cruelty, or anything else that could 
                    reasonably be found to constitute hate speech or otherwise infringe upon the rights of others.
                    </li>
                    <li>
                    Terms of License<br/>
                    The License granted above applies only to the extent that you continue to own the applicable WW NFT. If at any time you sell, trade, donate, give away, or otherwise transfer ownership of your WW NFT 
                    for any reason, the License granted will immediately be transferred to the new owner with respect to that WW NFT as set forth above without the requirement of notice, and you will have no further 
                    rights in or to the Art associated with that WW NFT. If you exceed the scope of the License without entering into a broader license agreement with or obtaining an exemption from Company, you acknowledge 
                    and agree that: (i) you are in breach of this License; (ii) in addition to any remedies that may be available to us at law or in equity, we may immediately terminate this License, without the requirement of 
                    notice; and (iii) you will be responsible to reimburse us for any costs and expenses incurred by us during the course of enforcing the terms of this License against you.
                    </li>
                </ol>
                <br/>
                <br/>
                You further agree that you are not receiving any copyright interest in the WW NFT or its content, and indeed, you agree that we may sell, license, modify, display, broadcast and create derivative works 
                based upon your WW NFT or its content. Any commercial exploitation of the WW NFT could subject you to claims of copyright infringement. 
                <br/>
                If you are unsure whether a contemplated use of the Platform or a WW NFT would violate these Terms, please contact us. 
                </small>
            </p>
            
            <br/>
            <b>MODIFICATIONS, INTERRUPTIONS</b>
            <p>
                <small>
                We may, in our sole discretion, make any changes or modifications to these Terms or replace them at any time and for any reason. We will notify you of any changes by posting the revised Terms on our Platform and 
                updating the date at the top of these Terms, and you hereby agree to waive any right to receive additional notices of any changes or modifications. You are responsible to review these Terms occasionally and your 
                continued use of the Platform will be deemed as an acceptance of such revised Terms. If you do not agree with the changes, you must discontinue use of the Platform.
                <br/>
                <br/>
                We reserve the right to change, modify, or remove any content from the Platform at any time or for any reason at our sole discretion, without notice and we have no obligation to update any information or 
                content on our Platform. We also reserve the right to modify or discontinue all or part of the Platform without notice. As such, we will not be liable to you or any third party for any modification of content, 
                change, suspension, or discontinuance of the Platform.
                <br/>
                <br/>
                While we strive to ensure the Platform is available 24/7, we cannot guarantee it will be. We may experience hardware, software, or other problems or need to perform maintenance related to the Platform, which may 
                result in interruptions, delays, or errors on the Platform. 
                <br/>
                <br/>
                You agree and acknowledge that we shall not be liable for any delays, errors or interruptions in the transmission or delivery of data via the Platform and we shall have no liability whatsoever for any loss, damage, 
                or inconvenience caused by your inability to access or use the Platform during any downtime, maintenance or discontinuance. Nothing in these Terms shall be construed as an obligation for us to maintain the Platform 
                or to supply any corrections, updates, or releases in connection therewith.
                </small>
            </p>
            <br/>
            <b>TERMINATION</b>
            <p>
                <small>
                Company has the right, without providing notice to you, to take all or any of the following actions (with or without notice), or take any other action we reasonably deem appropriate: 
                <br/>
                <ol type="a">
                    <li>
                        terminate or suspend your access to all or part of the Platform for any or no reason, including, without limitation, any violation of these Terms;
                    </li>
                    <li>
                        issue a warning to you; 
                    </li>
                    <li>
                        take appropriate legal action, including, without limitation, referral to law enforcement or regulatory authority, or notifying the harmed party of any illegal or unauthorized use of the Platform. 
                        Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of 
                        anyone posting any materials on or through the Platform; and,
                    </li>
                    <li>
                        initiate legal action against you, including proceedings for reimbursement of all costs (including, but not limited to, reasonable administrative and legal costs) resulting from your breach of these Terms.
                    </li>
                </ol>
                </small>
            </p>
            <br/>
            <b>USERS OUTSIDE OF CANADA</b>
            <p>
                <small>
                The Company is a Canadian corporation. Although the Platform is accessible worldwide, not all features, products or services discussed, referenced, provided or offered through or on the Platform are available to 
                all persons or in all geographic locations, or appropriate, available or legal for use outside of Canada. If you choose to access the Platform from outside of Canada, you do so on your own initiative and you are 
                solely responsible for complying with applicable laws. We make no representations that our Platform will be available for access or use outside of Canada, but any access or use outside of Canada will be subject to 
                these Terms. Access and use of this Platform is prohibited where this Platform is illegal. 
                </small>
            </p>
            <br/>
            <b>DISPUTES RESOLUTION</b>
            <p>
                <small>
                If you have a dispute with Company arising under these Terms, we will attempt to resolve such dispute through our support team. If we cannot resolve the dispute through our support team within 30 days of your notice 
                to Company regarding the dispute, then the determination of the dispute shall be settled solely and exclusively by final and conclusive binding arbitration in the City of Toronto, Province of Ontario, administered by 
                JAMS. Such arbitration shall be conducted in accordance with the then prevailing JAMS Streamlined Arbitration Rules & Procedures, with the following exceptions to such rules if in conflict: (a) there shall be a single 
                arbitrator mutually selected by the parties (or if the parties cannot agree, then mutually selected by arbitrators appointed by each of the parties); (b) each party to the arbitration will pay an equal share of 
                the expenses and fees of the arbitrator, together with other expenses of the arbitration incurred or approved by the arbitrator; (c) each party shall be entitled to participate in the arbitration by video conference 
                or teleconference; and (d) arbitration may proceed in the absence of any party if written notice (pursuant to the JAMS rules and regulations) of the proceedings has been given to such party. 
                
                <br/>
                <br/>
                Where applicable, both you and Company agree to bring any dispute in arbitration on an individual basis only, and not on a class or collective basis on behalf of others. Where applicable, there will be no right 
                or authority for any dispute to be brought, heard or arbitrated as a class or collective action, or as a member in any such class or collective proceeding (the “Class Action Waiver”).
                <br/>
                <br/>
                Notwithstanding any other provision of these Terms or the applicable arbitration rules, where applicable, disputes regarding the enforceability, revocability, scope, or 
                validity or breach of the Class Action Waiver may be resolved only by a civil court of competent jurisdiction and not by an arbitrator. In any case in which (1) the dispute is filed as a class or collective 
                action and (2) there is a final judicial determination that all or part of the Class Action Waiver is unenforceable, the class or collective action to that extent must be litigated in a civil court of competent 
                jurisdiction, but the portion of the Class Action Waiver that is enforceable shall be enforced in arbitration. You and Company agree that you will not be retaliated against, disciplined or threatened with 
                discipline as a result of your filing or participating in a class or collective action in any forum. However, we may lawfully seek enforcement of the arbitration clause set out above and the Class Action Waiver 
                under the applicable law and seek dismissal of such class or collective actions or claims.
                <br/>
                <br/>
                If for any reason the arbitration provisions set out above is not applicable, then each party, (i) to the fullest extent permitted by applicable law, hereby irrevocably waives all right to trial by jury in 
                relation to any issue relating to these Terms of Use or any other matter involving the parties, and (ii) submits to the exclusive jurisdiction and venue of the courts located in the City of Toronto, 
                Province of Ontario. These Terms do not hinder the rights of either party to seek injunctive relief in order to limit immediate, serious and irreparable injury.
                </small>
            </p>
            <br/>
            <b>ENTIRE AGREEMENT</b>
            <p>
                <small>
                These Terms and our Privacy Policy, constitute the entire agreement and understanding between you and us relating to your use of the Platform and WW NFTs. Our failure to insist on, exercise or enforce any 
                right or provision of these Terms shall not be considered or deemed as a waiver of such rights. Any waiver must be in writing to be effective. If any provision of these Terms is held to be invalid or 
                unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms supersede and replace any prior agreements we might have between us regarding the Platform and WW NFTs.
                </small>
            </p>
            <br/>
            <b>GOVERNING LAW</b>
            <p>
                <small>
                This Agreement shall be interpreted and construed in accordance with the laws of the Province of Ontario and the federal laws of Canada applicable therein. 
                </small>
            </p>
            <br/>
            <b>ASSIGNMENT</b>
            <p>
                <small>
                You may not assign any rights and/or licenses granted under these Terms. We reserve the right to assign our rights without restriction, including without limitation to any Company 
                affiliates or subsidiaries, or to any successor in interest of any business associated with the Platform. Any attempted transfer or assignment in violation hereof shall be null and void. 
                Subject to the foregoing, these Terms will bind and inure to the benefit of the parties, their successors and permitted assigns. 
                </small>
            </p>
            <br/>
            <b>SEVERABILITY</b>
            <p>
                <small>
                If any provision of these Terms is determined to be invalid or unenforceable under or by any regulation, law, court of competent jurisdiction, or any government agency, such provision will be 
                changed and interpreted to accomplish the objectives of the provision to the greatest extent possible under any applicable law and the validity or enforceability of any other provision of these Terms 
                shall not be affected. 
                </small>
            </p>
            <br/>
            <b>CHANGE OF CONTROL</b>
            <p>
                <small>
                Should Company be acquired by or merged with a third-party entity, we reserve the right, in any of these circumstances, to transfer or assign the information we have collected from you as part of such merger, 
                acquisition, sale, or other change of control. 
                </small>
            </p>
            <br/>
            <b>SURVIVAL</b>
            <p>
                <small>
                All provisions of these Terms which by their nature extend beyond the expiration or termination of these Terms, including, without limitation, those sections pertaining to suspension or termination, cancellation, 
                general use of the Platform, disputes with Company, and general provisions, shall survive the termination or expiration of these Terms.
                </small>
            </p>
            <br/>
            <b>CONTACT US</b>
            <p>
                <small>
                If you have any questions about these Terms or if you have a complaint, please contact us via email at: <a href="mailto:wisewhales.sol@gmail.com">wisewhales.sol@gmail.com.</a>
                </small>
            </p>





























          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      </Col>
    );
};