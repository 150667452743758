import React, { useState } from "react";
import {
  FAQWrapper,
  FAQInnerWrapper,
  FAQContent,
  AccordionWrapper,
  FAQImage,
} from "./FAQ.style";
import {ModalAccordian} from "./ModalAccordian";
import { SectionHeader, SectionSubheader } from "../../styles/common.styles";
import Accordion from "../../components/accordion/Accordion";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import FooterNavbar from "../../components/footer-navbar/FooterNavbar";
const FAQ = () => {
  const [openedId, setOpenedId] = useState(0);
  const [show, setShow] = useState(false);
  const showModal = () => setShow(true);



  const handleClose = () => setShow(false);
  const handleAccordionClick = (id) => setOpenedId(id);

  return (
    <FAQWrapper id="faq">

      <FAQInnerWrapper>
        <SectionHeader>FAQ</SectionHeader>
        <SectionSubheader>
          Stay ahead of the game. The Wise Whales will know first.
        </SectionSubheader>
        <FAQContent>
          <Modal variant="secondary" size="lg" className="LegalModal" show={show} onHide={handleClose}>
            <Modal.Header>
              <ModalAccordian/>
            </Modal.Header>
            <Modal.Body>
</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <FAQImage src="faq-image.png" alt="faq" />
          <AccordionWrapper>
            <Accordion
              id={0}
              opened={openedId === 0}
              toggleAccordion={handleAccordionClick}
              title="What can this data help me with?"
              text="Knowing what the smartest money in DeFi is doing can help you  make your own decisions: stabling up, pushing into smaller cap tokens, aping into the hottest NFT collections. If the Wise Whales are doing it, take notice!"
            />
            <Accordion
              id={1}
              opened={openedId === 1}
              toggleAccordion={handleAccordionClick}
              title="Wen mint?"
              text="This will in part be a community decision. Stay tuned in the discord!"
            />
            <Accordion
              id={2}
              opened={openedId === 2}
              toggleAccordion={handleAccordionClick}
              title="How come the data is aggregated over the wallets?"
              text="We decided to keep the wallets anonymous and just display the aggregated data of the wallets (the sum) for each time period. The main reason for doing this is to respect the privacy of those wallets. We also don’t want to scare them into using a burner wallet! We decided this was the right balance of information and privacy."
            />
            <Accordion
              id={3}
              opened={openedId === 3}
              toggleAccordion={handleAccordionClick}
              title="Will there be another drop?"
              text="For Wise Whales, no. However we will be partnering with a big data provider in the space to build a suite of on-chain analysis tools. Wise Whales will have WL opportunities for this."
            />
          </AccordionWrapper>

        </FAQContent>

        <FooterNavbar modalPopup={showModal} />
      </FAQInnerWrapper>
    </FAQWrapper>
  );
};

export default FAQ;
