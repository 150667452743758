import styled from "styled-components/macro";
import { device } from "../../styles/device";
import { SectionHeader } from "../../styles/common.styles";

export const HomeBannerWrapper = styled.div`
  padding: 0 20px 480px;
  background-image: url("whale-main-bg-mobile.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #000;

  @media ${device.laptop} {
    background-image: url("whale-main-bg.png");
    padding-bottom: 154px;
  }
`;

export const HomeBannerInnerWrapper = styled.div`
  max-width: 1178px;
  margin: 0 auto;
  padding-top: 90px;
`;

export const HomeBannerHeading = styled(SectionHeader)`
  margin-bottom: 0;
  font-family: "Seagram", sans-serif;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 0.03rem;

  @media ${device.laptop} {
    font-size: 60px;
    line-height: 75px;
  }
`;

export const HomeBannerTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 20px;

  @media ${device.laptop} {
    max-width: 57%;
    padding-top: 90px;
  }
`;

export const HomeBannerText = styled.p`
  margin: 9px auto 27px;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.03rem;
  color: #ffffff;
  font-family: "Poppins", sans-serif;

  @media ${device.laptop} {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 54px;
  }
`;

export const HomeButton = styled.a`
  width: 235px;
  padding: 16px 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.03rem;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  text-transform: capitalize;
  background-image: url("button-bg.png");
  background-size: contain;

  @media ${device.laptop} {
    width: 323px;
    padding: 23px 0;
    font-size: 20px;
    line-height: 30px;
    background-size: cover;
  }
`;
