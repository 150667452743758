import styled from "styled-components/macro";
import { device } from "../../styles/device";

export const AccordionWrapper = styled.div`
  background: rgba(11, 11, 11, 0.5);
  border: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(90.39deg, #ffd965 0.27%, #a04e24 99.65%);
`;

export const AccordionTitle = styled.p`
  display: flex;
  justify-content: space-between;
  padding: 14px;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  letter-spacing: 0.03rem;
  color: #ffd965;
  cursor: pointer;

  & img {
    vertical-align: middle;
    padding-left: 10px;
  }

  @media ${device.tablet} {
    padding: 19px 26px 10px;
    font-size: 18px;
    line-height: 26px;
  }
`;

export const AccordionText = styled.p`
  display: ${({ opened }) => (opened ? "block" : "none")};
  font-family: "Poppins", sans-serif;
  padding: 0 26px 19px;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.03rem;
  color: #ffffff;

  @media ${device.tablet} {
    font-size: 18px;
    line-height: 28px;
  }
`;
