import React from "react";
import {
  CollectionWrapper,
  CollectionSectionHeader,
  CollectionItemMobile,
  CollectionContentWrapper,
  CollectionSectionSubheader,
  CollectionItem,
} from "./Collection.style";

const Collection = () => {
  return (
    <CollectionWrapper id="collection">
      <CollectionContentWrapper>
        <CollectionSectionHeader>The Collection</CollectionSectionHeader>
        <CollectionSectionSubheader>
          4000-6000 Wise Whales on the Solana blockchain. Each will grant access to
          detailed charts and visualizations of the Wise Whales data, as well as
          access to weekly discussions and notifications of when the Wise Whales
          are on the move.
        </CollectionSectionSubheader>
        <CollectionItem src="collection-item.png" alt="collection" />
        <CollectionItemMobile
          src="collection-item-mobile.png"
          alt="collection"
        />
      </CollectionContentWrapper>
    </CollectionWrapper>
  );
};

export default Collection;
