import React from "react";
import {
  NavbarWrapper,
  NavbarContent,
  Logo,
  LinksWrapper,
  Links,
  Link,
  SocialLinks,
  SocialLogo,
} from "./FooterNavbar.style";

import { useState } from "react";
const FooterNavbar = ({modalPopup}) => {


  return (
    <NavbarWrapper>
      <NavbarContent>
        <a href="/">
          <Logo src="logo.png" />
        </a>
        <LinksWrapper>
          <Links>
            <Link href="#data">About</Link>
            <Link href="#collection">Collection</Link>
            <Link href="#team">Team</Link>
            <Link href="https://www.notion.so/Litepaper-4c19e9368c4f43f282c8e1ef0da69931#69a4445d16614c8bbc930079419ffe3er">
            Whitepaper
            </Link>
            <Link href="#faq">FAQ</Link>
            <Link href="javascript:void(0)" onClick={modalPopup}>Legal</Link>
          </Links>
          <SocialLinks>
            <a
              href="https://twitter.com/WiseWhales"
              target="_blank"
              rel="noreferrer"
            >
              <SocialLogo src="twitter-logo.svg" />
            </a>
            <a
              href="https://discord.gg/7jSgZTwJrU"
              target="_blank"
              rel="noreferrer"
            >
              <SocialLogo src="discord-logo.svg" />
            </a>
          </SocialLinks>
        </LinksWrapper>
      </NavbarContent>
    </NavbarWrapper>
  );
};

export default FooterNavbar;
