import React from "react";
import {
  AccordionWrapper,
  AccordionTitle,
  AccordionText,
} from "./Accordion.style";

const Accordion = ({ id, title, text, opened, toggleAccordion }) => (
  <AccordionWrapper opened={opened} onClick={() => toggleAccordion(id)}>
    <AccordionTitle>
      <span>{title}</span>
      <span>
        {opened ? <img src="open-icon.svg" /> : <img src="close-icon.svg" />}
      </span>
    </AccordionTitle>
    <AccordionText opened={opened}>{text}</AccordionText>
  </AccordionWrapper>
);

export default Accordion;
