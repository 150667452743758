import React from "react";
import {
  HomeBannerWrapper,
  HomeBannerInnerWrapper,
  HomeBannerHeading,
  HomeBannerTextWrapper,
  HomeBannerText,
  HomeButton,
} from "./HomeBanner.style";

const HomeBanner = () => {
  return (
    <HomeBannerWrapper>
      <HomeBannerInnerWrapper>
        <HomeBannerTextWrapper>
          <HomeBannerHeading as="h2">Track, Analyze, Invest.</HomeBannerHeading>
          <HomeBannerText>
            There is money and there is smart money. We have tracked down all
            the Solana DeFi whales and evaluated their trades and positions to
            find the most profitable whales - the Wise Whales. We present their
            present and historical data to you so that you can make smarter
            decisions to become be a Wise Whale too.
          </HomeBannerText>
          <HomeButton

            href="https://discord.gg/7jSgZTwJrU"
            target="_blank"
            rel="noreferrer"
          >
            Join our Discord
          </HomeButton>
        </HomeBannerTextWrapper>
      </HomeBannerInnerWrapper>
    </HomeBannerWrapper>
  );
};

export default HomeBanner;
