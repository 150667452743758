import React from "react";
import Navbar from "../../components/navbar/Navbar";
import HomeBanner from "../../components/home-banner/HomeBanner";
import FAQ from "../faq/FAQ";
import DataSection from "../data-section/DataSection";
import Collection from "../collection/Collection";
import OurTeam from "../our-team/OurTeam";
function App(props) {

  return (
    <>
      <Navbar />
      <HomeBanner />
      <DataSection />
      <Collection />
      <OurTeam />
      <FAQ />
    </>
  );
}

export default App;
