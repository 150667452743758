import React from "react";
import {
  OurTeamWrapper,
  OurTeamSectionHeader,
  OurTeamList,
} from "./OurTeam.style";
import OurTeamMember from "./OurTeamMember";

const OurTeam = () => {
  return (
    <OurTeamWrapper id="team">
      <OurTeamSectionHeader>Meet Our Team</OurTeamSectionHeader>
      <OurTeamList>
        <OurTeamMember src="team-member-1.png" name="Dave" title="Founder" />
        <OurTeamMember
          src="team-member-2.png"
          name="Tarique"
          title="Developer"
        />
        <OurTeamMember src="team-member-3.png" name="Mike" title="Developer" />
        {/* <OurTeamMember src="team-member-4.png" name="Alex" title="Developer" /> */}
      </OurTeamList>
    </OurTeamWrapper>
  );
};

export default OurTeam;
