import styled from "styled-components/macro";
import { SectionHeader } from "../../styles/common.styles";
import { device } from "../../styles/device";

export const OurTeamWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-image: url("out-team-bg-mobile.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #000;

  @media ${device.mobileL} {
    padding: 110px 0 210px;
    background-image: url("out-team-bg.png");
  }
`;

export const OurTeamSectionHeader = styled(SectionHeader)`
  margin: 80px auto 50px;
  @media ${device.tablet} {
    margin-top: 0;
  }
`;

export const OurTeamFrame = styled.img`
  height: 309px;
  max-width: 247px;
`;

export const OurTeamList = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1363px;
  padding: 0 20px;
  flex-wrap: wrap;
`;

export const OurTeamItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 40px;

  @media ${device.tablet} {
    &:not(:last-child) {
      margin-right: 24px;
    }
  }
`;

export const OurTeamMemberAvatar = styled.img`
  max-width: 162px;
  position: absolute;
  top: 38px;
  left: 42px;
`;

export const OurTeamMemberNameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`;

export const OurTeamMemberName = styled.p`
  font-family: "Poppins", sans-serif;
  margin: 0;
  font-size: 26px;
  line-height: 36px;
  letter-spacing: 0.03rem;
  color: #fafafa;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.55);

  @media ${device.tablet} {
    font-size: 26px;
    line-height: 36px;
  }
`;

export const OurTeamMemberTitle = styled.p`
  font-family: "Poppins", sans-serif;
  margin: 10px auto 5px;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.03rem;
  color: #ffffff;

  @media ${device.tablet} {
    font-size: 16px;
    line-height: 28px;
  }
`;
